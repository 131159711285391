import React, { Component } from "react"
import { Items, Input, TextArea, Item } from "./style"
import Button from "../button"

export default class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      message: "",
    }
  }
  render() {
    return (
      <Items>
        <Item>お名前</Item>
        <Input
          placeholder={"Name"}
          onChange={e => this.setState({ name: e.target.value })}
        />
        <Item>メールアドレス</Item>
        <Input
          placeholder={"Email"}
          onChange={e => this.setState({ email: e.target.value })}
        />
        <Item>お問い合わせ内容</Item>
        <TextArea
          placeholder={"Message"}
          onChange={e => this.setState({ message: e.target.value })}
        />
        <Button onClick={() => this.postSlack(this.state)} type="submit">
          送信する
        </Button>
      </Items>
    )
  }

  postSlack = async () => {
    const { name, email, message } = this.state
    const body = {
      attachments: [
        {
          pretext: `Lyactコーポレートよりお問い合わせがありました`,
          author: `氏名: ${name}`,
          title: `お問い合わせ詳細`,
          fields: [
            {
              title: `名前`,
              value: `${name}`,
            },
            {
              title: "メールアドレス",
              value: `${email}`,
              short: true,
            },
            {
              title: "問い合わせ内容",
              value: `${message}`,
            },
          ],
          footer: "Slack API",
          color: "#4A90E2",
        },
      ],
    }

    const url =
      "https://hooks.slack.com/services/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"
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: JSON.stringify(body),
    })
    if (res.ok) {
      alert(
        "お問い合わせを送信しました。折り返し、担当者よりご連絡いたしますので、 恐れ入りますが、しばらくお待ちください。"
      )
    } else {
      alert("お問い合わせに失敗しました。お手数ですがもう一度お試しください。")
    }
  }
}
