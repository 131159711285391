import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageWrapper, Text } from "../components/main"
import ContactForm from "../components/contactForm"
import { Container, Img } from "../styles/contact"

const Contact = () => (
  <Layout>
    <SEO title="Contact us" />
    <PageWrapper>
      <h1>Contact us</h1>
      <h3>お問い合わせ</h3>
      <Text style={{ marginTop: 30 }}>
        株式会社Lyactに興味をお持ちいただき、ありがとうございます。下記のフォーム項目を入力の上、
        送信ボタンを押してください。
      </Text>
      <Container>
        <ContactForm />
        <Img src="https://assets-global.website-files.com/5bfd1275cc56e15ce750b18e/5d052f246a936012949361e8_Delivery%20%232.png" />
      </Container>
    </PageWrapper>
  </Layout>
)

export default Contact
