import styled from "styled-components"

const Button = styled.button`
  min-width: 200px;
  padding: 20px;
  background: #222;
  border: solid 2px #222;
  color: white;
  border-radius: 40px;
  font-weight: 600;
  font-family: "Montserrat";
  font-size: 1.4em;
  outline: none;
  cursor: pointer;
  margin: 40px auto;
  background-size: 200% 100%;
  background-image: -webkit-linear-gradient(left, transparent 50%, white 50%);
  -webkit-transition: background-position 0.6s cubic-bezier(0.19, 1, 0.22, 1)
      0.2s,
    color 1s ease, background-color 1s ease, letter-spacing 0.4s ease;
  :hover {
    color: #222;
    background-color: white;
    background-position: -100%;
    letter-spacing: 0.2rem;
    border: solid 2px #222;
  }
`
export default Button
