import styled from "styled-components"
import { Col } from "../main"

export const Items = styled(Col)`
  width: 100%;
  margin-top: 30px;
`

export const Input = styled.input`
  max-width: calc(100% - 40px);
  min-width: calc(100% - 40px);
  background: #edf2f7;
  border: none;
  outline: none;
  padding: 15px 20px;
  margin-bottom: 20px;
  border-radius: 10px;
`

export const TextArea = styled.textarea`
  max-width: calc(100% - 40px);
  min-width: calc(100% - 40px);
  min-height: 200px;
  background: #edf2f7;
  border: none;
  outline: none;
  padding: 15px 20px;
  border-radius: 10px;
`

export const Item = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 10px;
`
