import styled from "styled-components"
import { Col } from "../components/main"

export const Container = styled(Col)`
  width: 100%;
  @media screen and (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
`

export const Img = styled.img`
  width: 240px;
  margin: 50px auto 0;
  @media screen and (min-width: 1024px) {
    width: 30%;
    margin: 0 0 5% 15%;
  }
`
